import React from "react";

export default function Shows() {
    const ColoredLine = () => (
        <hr/>
    );
    
    return (
        <div>
            <p className="shows-blurb">
                <strong><a className="shows-link" href="/contact">Join our mailing list</a></strong> to get notified about shows in your area.
            </p>
            <h3>UPCOMING</h3>
            <ColoredLine />
            <h3>PAST</h3>
            <ColoredLine />
            <div className="show">
                <div className="show-details">
                    <p className="show-date">SAT 27 JUL 2024</p>
                    <p className="show-venue">The Lansdowne Hotel</p>
                    <p className="show-geo">Sydney, NSW</p>
                </div>
                <div className="show-links">
                    <a target="_blank" rel="noopener noreferrer" className="show-button" href="https://www.moshtix.com.au/v2/event/a-splendid-saturday-the-lansdowne-w-bin-birds-casual-fan-morgues/169128">FB</a>
                </div>
            </div>
            <ColoredLine />
            <div className="show">
                <div className="show-details">
                    <p className="show-date">SUN 21 JUL 2024</p>
                    <p className="show-venue">Yulli's Brews</p>
                    <p className="show-geo">Sydney, NSW</p>
                </div>
                <div className="show-links">
                    <a target="_blank" rel="noopener noreferrer" className="show-button" href="https://events.humanitix.com/dusty-sundays-casual-fan">FB</a>
                </div>
            </div>
            <ColoredLine />
            <div className="show">
                <div className="show-details">
                    <p className="show-date">SAT 20 JUL 2024</p>
                    <p className="show-venue">Black Market Roasters</p>
                    <p className="show-geo">Sydney, NSW</p>
                </div>
                {/* <div className="show-links">
                    <a target="_blank" rel="noopener noreferrer" className="show-button" href="https://events.humanitix.com/dusty-sundays-casual-fan">FB</a>
                </div> */}
            </div>
            <ColoredLine />
            <div className="show">
                <div className="show-details">
                    <p className="show-date">WED 22 MAY 2024</p>
                    <p className="show-venue">HOUSE OF STRINGS</p>
                    <p className="show-geo">Terrigal, NSW</p>
                </div>
                <div className="show-links">
                    <a target="_blank" rel="noopener noreferrer" className="show-button" href="https://fb.me/e/1QWUFi8bk">FB</a>
                </div>
            </div>
            <ColoredLine />
            <div className="show">
                <div className="show-details">
                    <p className="show-date">FRI 10 MAY 2024</p>
                    <p className="show-venue">Low 302</p>
                    <p className="show-geo">Sydney, NSW</p>
                </div>
                <div className="show-links">
                    <a target="_blank" rel="noopener noreferrer" className="show-button" href="https://fb.me/e/6NzqA1bIv">FB</a>
                </div>
            </div>
            <ColoredLine />
            <div className="show">
                <div className="show-details">
                    <p className="show-date">SAT 07 OCT 2023</p>
                    <p className="show-venue">Goodspace Gallery</p>
                    <p className="show-geo">Sydney, NSW</p>
                </div>
                <div className="show-links">
                    <a target="_blank" rel="noopener noreferrer" className="show-button" href="https://fb.me/e/1leEczm52">FB</a>
                </div>
            </div>
            <ColoredLine />
            <div className="show">
                <div className="show-details">
                    <p className="show-date">SAT 27 MAY 2023</p>
                    <p className="show-venue">Ashfield Bowling Club</p>
                    <p className="show-geo">Sydney, NSW</p>
                </div>
                <div className="show-links">
                    <a target="_blank" rel="noopener noreferrer" className="show-button" href="https://fb.me/e/3x4Nd9gTY">FB</a>
                </div>
            </div>
            <ColoredLine />
            <div className="show">
                <div className="show-details">
                    <p className="show-date">THU 23 FEB 2023</p>
                    <p className="show-venue">The Espy</p>
                    <p className="show-geo">Melbourne, VIC</p>
                </div>
                <div className="show-links">
                    <a target="_blank" rel="noopener noreferrer" className="show-button" href="https://fb.me/e/6JvDPEDOK">FB</a>
                </div>
            </div>
            <ColoredLine />
            <div className="show">
                <div className="show-details">
                    <p className="show-date">FRI 10 FEB 2023</p>
                    <p className="show-venue">OAF Gallery</p>
                    <p className="show-geo">Sydney, NSW</p>
                </div>
                <div className="show-links">
                    <a target="_blank" rel="noopener noreferrer" className="show-button" href="https://fb.me/e/25ud5Oy5t">FB</a>
                </div>
            </div>
            <ColoredLine />
            <div className="show">
                <div className="show-details">
                    <p className="show-date">FRI 30 DEC 2022</p>
                    <p className="show-venue">Lost Paradise Festival</p>
                    <p className="show-geo">Glenworth Valley, NSW</p>
                </div>
                <div className="show-links">
                    <a target="_blank" rel="noopener noreferrer" className="show-button" href="https://fb.me/e/77MkYSTVv">FB</a>
                </div>
            </div>
            <ColoredLine />
            <div className="show">
                <div className="show-details">
                    <p className="show-date">THU 08 DEC 2022</p>
                    <p className="show-venue">OAF Gallery</p>
                    <p className="show-geo">Sydney, NSW</p>
                </div>
                <div className="show-links">
                    <a target="_blank" rel="noopener noreferrer" className="show-button" href="https://fb.me/e/1FKAaB00M">FB</a>
                </div>
            </div>
            <ColoredLine />
            <div className="show">
                <div className="show-details">
                    <p className="show-date">WED 30 NOV 2022</p>
                    <p className="show-venue">The Retreat Hotel</p>
                    <p className="show-geo">Melbourne, VIC</p>
                </div>
                <div className="show-links">
                    <a target="_blank" rel="noopener noreferrer" className="show-button" href="https://fb.me/e/3q3BxoCN0">FB</a>
                </div>
            </div>
            <ColoredLine />
            <div className="show">
                <div className="show-details">
                    <p className="show-date">SUN 21 AUG 2022</p>
                    <p className="show-venue">Yulli's Brews</p>
                    <p className="show-geo">Sydney, NSW</p>
                </div>
                <div className="show-links">
                    <a target="_blank" rel="noopener noreferrer" className="show-button" href="https://fb.me/e/4CrL6U1NH">FB</a>
                </div>
            </div>
            <ColoredLine />
            <div className="show">
                <div className="show-details">
                    <p className="show-date">FRI 27 MAY 2022</p>
                    <p className="show-venue">Yulli's Brews</p>
                    <p className="show-geo">Sydney, NSW</p>
                </div>
                <div className="show-links">
                    <a target="_blank" rel="noopener noreferrer" className="show-button" href="https://fb.me/e/8DF91L9Ig">FB</a>
                </div>
            </div>
            <ColoredLine />
            <div className="show">
                <div className="show-details">
                    <p className="show-date">FRI 28 MAY 2021</p>
                    <p className="show-venue">The Servo</p>
                    <p className="show-geo">Port Kembla, NSW</p>
                </div>
                <div className="show-links">
                    <a target="_blank" rel="noopener noreferrer" className="show-button" href="https://fb.me/e/3EyzGjEVq">FB</a>
                </div>
            </div>
            <ColoredLine />
            <div className="show">
                <div className="show-details">
                    <p className="show-date">SAT 03 APR 2021</p>
                    <p className="show-venue">Waywards</p>
                    <p className="show-geo">Sydney, NSW</p>
                </div>
                <div className="show-links">
                    <a target="_blank" rel="noopener noreferrer" className="show-button" href="https://fb.me/e/h08FeIxqv">FB</a>
                </div>
            </div>
            <ColoredLine />
            <div className="show">
                <div className="show-details">
                    <p className="show-date">FRI 28 MAY 2021</p>
                    <p className="show-venue">Botany View Hotel</p>
                    <p className="show-geo">Sydney, NSW</p>
                </div>
                <div className="show-links">
                    <a target="_blank" rel="noopener noreferrer" className="show-button" href="https://fb.me/e/773tOU4jE">FB</a>
                </div>
            </div>
            <ColoredLine />
            <div className="show">
                <div className="show-details">
                    <p className="show-date">THU 25 FEB 2021</p>
                    <p className="show-venue">Civic Underground</p>
                    <p className="show-geo">Sydney, NSW</p>
                </div>
                <div className="show-links">
                    <a target="_blank" rel="noopener noreferrer" className="show-button" href="https://fb.me/e/6PRXvvnZj">FB</a>
                </div>
            </div>
            <ColoredLine />
            <div className="show">
                <div className="show-details">
                    <p className="show-date">SAT 20 FEB 2021</p>
                    <p className="show-venue">The Chippo Hotel</p>
                    <p className="show-geo">Sydney, NSW</p>
                </div>
                <div className="show-links">
                    <a target="_blank" rel="noopener noreferrer" className="show-button" href="https://fb.me/e/3tnselyKr">FB</a>
                </div>
            </div>
            <ColoredLine />
            <div className="show">
                <div className="show-details">
                    <p className="show-date">THU 11 FEB 2021</p>
                    <p className="show-venue">Civic Underground</p>
                    <p className="show-geo">Sydney, NSW</p>
                </div>
                <div className="show-links">
                    <a target="_blank" rel="noopener noreferrer" className="show-button" href="https://fb.me/e/26E9EObWR">FB</a>
                </div>
            </div>
            <ColoredLine />
            <div className="show">
                <div className="show-details">
                    <p className="show-date">FRI 29 JAN 2021</p>
                    <p className="show-venue">Vic on the Park</p>
                    <p className="show-geo">Sydney, NSW</p>
                </div>
                <div className="show-links">
                    <a target="_blank" rel="noopener noreferrer" className="show-button" href="https://fb.me/e/22OmgKBTh">FB</a>
                </div>
            </div>
            <ColoredLine />
            <div className="show">
                <div className="show-details">
                    <p className="show-date">FRI 20 NOV 2020</p>
                    <p className="show-venue">Wombarra Bowling Club</p>
                    <p className="show-geo">Wombarra, NSW</p>
                </div>
                <div className="show-links">
                    <a target="_blank" rel="noopener noreferrer" className="show-button" href="https://fb.me/e/5zxtLUSAo">FB</a>
                </div>
            </div>
            <ColoredLine />
            <div className="show">
                <div className="show-details">
                    <p className="show-date">THU 19 NOV 2020</p>
                    <p className="show-venue">The Vanguard</p>
                    <p className="show-geo">Sydney, NSW</p>
                </div>
                <div className="show-links">
                    <a target="_blank" rel="noopener noreferrer" className="show-button" href="https://fb.me/e/647LQmJfz">FB</a>
                </div>
            </div>
            <ColoredLine />
            <div className="show">
                <div className="show-details">
                    <p className="show-date">TUE 04 AUG 2020</p>
                    <p className="show-venue">Lazybones Lounge</p>
                    <p className="show-geo">Sydney, NSW</p>
                </div>
                <div className="show-links">
                    <a target="_blank" rel="noopener noreferrer" className="show-button" href="https://fb.me/e/3puICv5ZI">FB</a>
                </div>
            </div>
            <ColoredLine />
            <div className="show">
                <div className="show-details">
                    <p className="show-date">FRI 10 JUL 2020</p>
                    <p className="show-venue">Kelly's On King</p>
                    <p className="show-geo">Sydney, NSW</p>
                </div>
                <div className="show-links">
                    <a target="_blank" rel="noopener noreferrer" className="show-button" href="https://fb.me/e/1Dh4VjMbh">FB</a>
                </div>
            </div>
            <ColoredLine />
            <div className="show">
                <div className="show-details">
                    <p className="show-date">WED 24 JUN 2020</p>
                    <p className="show-venue">Butcher's Brew</p>
                    <p className="show-geo">Sydney, NSW</p>
                </div>
                <div className="show-links">
                    <a target="_blank" rel="noopener noreferrer" className="show-button" href="https://fb.me/e/6nMhhloaQ">FB</a>
                </div>
            </div>
            <ColoredLine />
            <div className="show">
                <div className="show-details">
                    <p className="show-date">THU 12 MAR 2020</p>
                    <p className="show-venue">The Gasoline Pony</p>
                    <p className="show-geo">Sydney, NSW</p>
                </div>
                <div className="show-links">
                    <a target="_blank" rel="noopener noreferrer" className="show-button" href="https://fb.me/e/3AjJ8CKNP">FB</a>
                </div>
            </div>
            <ColoredLine />
            <div className="show">
                <div className="show-details">
                    <p className="show-date">MON 24 FEB 2020</p>
                    <p className="show-venue">Butcher's Brew</p>
                    <p className="show-geo">Sydney, NSW</p>
                </div>
                <div className="show-links">
                    <a target="_blank" rel="noopener noreferrer" className="show-button" href="https://fb.me/e/4rnx7f3IV">FB</a>
                </div>
            </div>
            <ColoredLine />
            <div className="show">
                <div className="show-details">
                    <p className="show-date">TUE 10 DEC 2019</p>
                    <p className="show-venue">The Edwards</p>
                    <p className="show-geo">Newcastle, NSW</p>
                </div>
                <div className="show-links">
                    <a target="_blank" rel="noopener noreferrer" className="show-button" href="https://fb.me/e/3zGoxMbcY">FB</a>
                </div>
            </div>
            <ColoredLine />
            <div className="show">
                <div className="show-details">
                    <p className="show-date">FRI 06 DEC 2019</p>
                    <p className="show-venue">Yulli's Brews</p>
                    <p className="show-geo">Sydney, NSW</p>
                </div>
                <div className="show-links">
                    <a target="_blank" rel="noopener noreferrer" className="show-button" href="https://fb.me/e/xHuSSPZNi">FB</a>
                </div>
            </div>
            <ColoredLine />
            <div className="show">
                <div className="show-details">
                    <p className="show-date">THU 05 DEC 2019</p>
                    <p className="show-venue">Franks Wild Years</p>
                    <p className="show-geo">Thirroul, NSW</p>
                </div>
            </div>
            <ColoredLine />
            <div className="show">
                <div className="show-details">
                    <p className="show-date">THU 21 NOV 2019</p>
                    <p className="show-venue">Fat Belly Jack's</p>
                    <p className="show-geo">Sydney, NSW</p>
                </div>
                <div className="show-links">
                    <a target="_blank" rel="noopener noreferrer" className="show-button" href="https://fb.me/e/1vIEL5t7S">FB</a>
                </div>
            </div>
            <ColoredLine />
            <div className="show">
                <div className="show-details">
                    <p className="show-date">FRI 13 SEP 2019</p>
                    <p className="show-venue">Kelly's On King</p>
                    <p className="show-geo">Sydney, NSW</p>
                </div>
                <div className="show-links">
                    <a target="_blank" rel="noopener noreferrer" className="show-button" href="https://fb.me/e/1PukCjd2g">FB</a>
                </div>
            </div>
            <ColoredLine />
            <div className="show">
                <div className="show-details">
                    <p className="show-date">SUN 18 AUG 2019</p>
                    <p className="show-venue">Lazybones Lounge</p>
                    <p className="show-geo">Sydney, NSW</p>
                </div>
                <div className="show-links">
                    <a target="_blank" rel="noopener noreferrer" className="show-button" href="https://fb.me/e/1Sfo6Msu0">FB</a>
                </div>
            </div>
            <ColoredLine />
        </div>
    )
};