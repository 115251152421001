import React from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

export default function Navs() {
    return (
        <div id="nav-links">
            <ul>
                <CustomLink to="/music">music</CustomLink>
                <CustomLink to="/shows">shows</CustomLink>
                <CustomLink to="/videos">videos</CustomLink>
                <CustomLink to="/bio">bio</CustomLink>
                <CustomLink to="/press-shots">press shots</CustomLink>
                <ExternalLink>merch</ExternalLink>
                <CustomLink to="/contact">contact</CustomLink>
            </ul>
        </div>
    )
};

function CustomLink({ to, children }) {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true });

    return (
        <li>
            <Link id="nav-link" className={isActive ? "active" : ""} to={to}>
                {children}
            </Link>
        </li>
    )
};

function ExternalLink({ children }) {
    return (
        <li>
            <a id="nav-link" href="https://casualfan.bandcamp.com/merch" target="_blank" rel="noopener noreferrer" >
                {children}
            </a>
        </li>
    )
};