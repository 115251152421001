import './App.css';
import React, { useEffect } from "react";
import Navbar from './components/Navbar';
import Home from "./pages/Home";
import Music from "./pages/Music";
import Shows from "./pages/Shows";
import Videos from "./pages/Videos";
import Bio from "./pages/Bio";
import PressShots from "./pages/PressShots";
import Lyrics from "./pages/Lyrics";
import Contact from "./pages/Contact";
import { Route, Routes } from "react-router-dom";

function App() {
  useEffect(() => {
    const burger = document.getElementById('burger-div');
    const navLinks = document.getElementById('nav-links');
    const content = document.getElementById('content');
    burger.addEventListener('click', () => {
      navLinks.classList.toggle('active');
      content.classList.toggle('expanded');
    })
  }, []);
  
  return (
    <div>
      <Navbar />
      <div id="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/music" element={<Music />} />
          <Route path="/shows" element={<Shows />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="/bio" element={<Bio />} />
          <Route path="/press-shots" element={<PressShots />} />
          <Route path="/lyrics" element={<Lyrics />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
// Start with "npm start"
// Push to GitHub with "npm run deploy"