import React from "react";
import Logo from './Logo.js';
import Navs from './Navs.js';
import Links from './Links.js';
import Burger from './Burger.js';

export default function Navbar() {
    return (
        <div className="navbar">
            <div className="top-collapse">
                <Logo />
                <Burger />
            </div> 
            <Navs />
            <Links />
        </div>
    )
}