import React from "react";
import IDATD from "../pics/IDATD.jpg";

export default function Lyrics() {
    const LineBreak = () => (
        <br/>
    );
    
    return (
        <div>
            <img className="press-shot" src={IDATD} alt="" />
            <div className="song">
                <h3>STRANGERS</h3>
                <p>You're running out</p>
                <p>Of reasons why you should stay here in this town</p>
                <p>I can see</p>
                <p>You're gonna leave but I wish you'd stick around</p>
                <LineBreak />
                <p>Would we just be</p>
                <p>Strangers if we met up down the road</p>
                <p>I couldn't find the words</p>
                <p>But if you saw it in my eyes then you would know</p>
                <LineBreak />
                <p>I'm speaking loudly but I'm not saying anything at all</p>
                <p>I'm speaking loudly but I'm not saying anything at all</p>
                <LineBreak />
                <p>You walk down this road until you find home</p>
                <p>You walk down this road until you find home</p>
                <p>You walk down this road until you find a home</p>
                <p>You walk down this road until you find a home</p>
            </div>
            <div className="song">
                <h3>STAY</h3>
                <p>The road forms a line in the sand</p>
                <p>Over the hill</p>
                <p>Over the land</p>
                <p>The sea has made a divide between you and I</p>
                <p>You're all that I've ever known</p>
                <p>But you've never really felt like home</p>
                <LineBreak />
                <p>Oh you know the reasons why</p>
                <p>You know the reasons why</p>
                <p>I can't stay</p>
                <LineBreak />
                <p>I remember those days with you</p>
                <p>My memories tangled up in blue</p>
                <p>It's 31 degrees and I</p>
                <p>And I'm up again</p>
                <p>Now I'm saying things I don't even mean</p>
                <p>My mind's burst at the seam</p>
                <LineBreak />
                <p>Oh you know the reasons why</p>
                <p>You know the reasons why</p>
                <p>I can't stay</p>
                <LineBreak />
                <p>Give me time</p>
                <p>Where would I run to but you</p>
                <p>Sleepless nights</p>
                <p>It's been this way since June</p>
                <p>Given time</p>
                <p>I don't know where I would be</p>
                <p>You know that I'll be fine</p>
                <p>Walking these far away streets</p>
                <LineBreak />
                <p>And now far past the borderline</p>
                <p>I'm changing my mind</p>
                <p>I'm leaving all the time</p>
                <p>But don't worry, I'll be fine</p>
                <p>Don't worry, I'll be fine</p>
            </div>
            <div className="song">
                <h3>BERLIN</h3>
                <p>Walk by your lonely wall</p>
                <p>Faint from the empty cold</p>
                <p>You’re post-apocalyptic</p>
                <p>But in a good way</p>
                <p>It took so long to see you</p>
                <p>But you say it’s ok</p>
                <LineBreak />
                <p>I don’t know</p>
                <p>I don't know</p>
                <p>What it is I’ve lost</p>
                <LineBreak />
                <p>Waking up to nothing</p>
                <p>Feels like it’s the end</p>
                <p>If we can’t survive as strangers</p>
                <p>Can we try being friends</p>
                <LineBreak />
                <p>I don’t know</p>
                <p>I don't know</p>
                <p>What it is I’ve lost</p>
            </div>
            <div className="song">
                <h3>FLIGHT</h3>
                <p>June 17th I missed my flight on the way back home again</p>
                <p>Spent a few days in a northern state</p>
                <p>We were so much older then</p>
                <p>We were so much older then</p>
                <LineBreak />
                <p>25 years in the making</p>
                <p>What's it gonna take to lose</p>
                <p>25 more years on this earth</p>
                <p>But not like I just did</p>
                <p>But not like I just did</p>
                <LineBreak />
                <p>Wait</p>
                <p>Don't wanna miss my flight again</p>
                <p>Cos I've been waiting all these years</p>
                <p>Can't wait to see your face again</p>
                <LineBreak />
                <p>All my things in boxes</p>
                <p>Lined up in a row</p>
                <p>Tell me should I stay here</p>
                <p>Cos I think I've got to go</p>
                <p>I think I've got to go</p>
                <LineBreak />
                <p>Wait</p>
                <p>Don't wanna miss my flight again</p>
                <p>Cos I've been waiting all these years</p>
                <p>Can't wait to see your face again</p>
                <LineBreak />
                <p>Slow</p>
                <p>I'm running through this terminal</p>
                <p>Don't let me hear that final call</p>
                <p>Until I hear it I'll be yours</p>
                <LineBreak />
                <p>This makes a mockery of us and time</p>
                <p>Will you save me a spot in the line</p>
                <p>I'll be there in a little while</p>
            </div>
            <div className="song">
                <h3>RUNNERS</h3>
                <p>Everything I own fits in the back of my car</p>
                <p>I'm driving south</p>
                <p>Going home</p>
                <p>Sydney's like another country though it's not so far</p>
                <p>I'm leaving town</p>
                <p>Going slow</p>
                <LineBreak />
                <p>I don't need another pair of helping hands</p>
                <p>I'm doing all I can to pay my dividends</p>
                <p>We should sit here watch the waves sometime</p>
                <p>Sitting in the car park looking at the runners</p>
                <LineBreak />
                <p>You said that you wanna be someone in the game</p>
                <p>Just take a breath</p>
                <p>Get some air</p>
                <p>I remember times you said that you needed to run away</p>
                <p>But you stayed there</p>
                <p>You stayed there</p>
                <LineBreak />
                <p>I don't need another pair of helping hands</p>
                <p>I'm doing all I can to pay my dividends</p>
                <p>We should sit here watch the waves sometime</p>
                <p>Sitting in the car park looking at the runners</p>
                <LineBreak />
                <p>Run by</p>
            </div>
            <div className="song">
                <h3>ALL MY DAYS</h3>
                <p>Come and make your home with us</p>
                <p>Oh I'd hate to have to catch the dust</p>
                <p>Watch my metal world all turn to rust</p>
                <p>Stay here, I'll wait</p>
                <p>Wait here all my days</p>
                <LineBreak />
                <p>If money gets a little tight</p>
                <p>It's fine cos we'll be staying in tonight</p>
                <p>Maybe reappear again sometime</p>
                <p>Oh I've dreamt about these days</p>
                <p>In my dreams they felt less grey</p>
                <LineBreak />
                <p>All this time I've been caught up in my mind</p>
                <p>Oh I let myself down</p>
                <p>I'm dragging my feet through these halls I've walked before</p>
                <p>I'll always open the door</p>
                <LineBreak />
                <p>And I'm afraid of what I will find</p>
                <p>Cos I'm always looking ahead in time</p>
                <p>I'm afraid of what I will find</p>
                <p>Cos I'm always looking ahead in time</p>
                <LineBreak />
                <p>Where are you now?</p>
                <p>I'll try to see you again under these western skies</p>
                <p>You'll disappear</p>
                <p>Another morning flight</p>
                <p>Stay here, I'll wait</p>
                <p>All my days in vain</p>
            </div>
            <div className="song">
                <h3>SUFFRAGETTE</h3>
                <p>Lovely suffragette</p>
                <p>Did you live your eighty years without regret?</p>
                <p>But did you tear up notes you wish you'd never read?</p>
                <LineBreak />
                <p>Lonely suffragette</p>
                <p>We're grateful for everything you've ever said</p>
                <p>And we miss you each winter we're not in NZ</p>
                <p>Since 2015 when we heard that you were dead</p>
                <LineBreak />
                <p>Are your flowers still in bloom?</p>
                <p>Did they redecorate your room?</p>
                <LineBreak />
                <p>I said some words</p>
                <p>Then we sang another verse</p>
                <p>Now the matriarch is gone</p>
                <p>So long</p>
                <LineBreak />
                <p>Long gone suffragette</p>
                <p>I write to you five years after your death</p>
                <p>I don't think I'll ever forget</p>
                <LineBreak />
                <p>Does the creek still run?</p>
            </div>
        </div>
    )
};