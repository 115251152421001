import React from "react";

export default function Contact() {
    const LineBreak = () => (
        <br/>
    );
    
    return (
        <div id="content">
            <h3 className="contact-blurb">Contact us via email at <span className="email-address">casualfanmusic@gmail.com</span></h3>
            <p>Or join our newsletter using the form below:</p>
            <div id="mc_embed_signup">
                <form action="https://facebook.us4.list-manage.com/subscribe/post?u=3df8ec732cbd865d43afbf6e1&amp;id=5578de8a56&amp;f_id=001657e9f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" novalidate>
                    <div id="mc_embed_signup_scroll">
                    <h2>Subscribe</h2>
            <div className="mc-field-group">
                <label for="mce-EMAIL">Email Address</label>
                <LineBreak />
                <input type="email" value="" name="EMAIL" className="required email" id="mce-EMAIL" required></input>
                <span id="mce-EMAIL-HELPERTEXT" className="helper_text"></span>
            </div>
            <LineBreak />
            <LineBreak />
                <div id="mce-responses" className="clear foot">
                            <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
                            <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
                </div>
                        <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true"><input type="text" name="b_3df8ec732cbd865d43afbf6e1_5578de8a56" tabindex="-1" value=""></input></div>
                    <div className="optionalParent">
                        <div className="clear foot">
                            <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button"></input>
                            <p className="brandingLogo"><a href="http://eepurl.com/h9eGWj" title="Mailchimp - email marketing made easy and fun"><img src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg" alt="" /></a></p>
                        </div>
                    </div>
                </div>
            </form>
            </div>
        </div>
    )
};