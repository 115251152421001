import React from "react";

export default function Logo() {
    return (
        <div id="burger-div">
            <span className="burger-bar"></span>
            <span className="burger-bar"></span>
            <span className="burger-bar"></span>
        </div>
    )
};