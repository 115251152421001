import React from "react";
import bioPic from "../pics/bio-header.jpg";

export default function Bio() {
    return (
        <div>
            <img className="bio-image" src={bioPic} alt="" />
            <p className="bio-text">Casual Fan is the musical project of six friends from Sydney, formed around the songwriting of bandleader, Nathan. With a love of the American folk tradition, the music of Casual Fan expands beyonds the borders of this genre, exploring diverse arrangements and themes of connection and loss, decorating simple frames with dissonance and complexity.</p>
            <p className="bio-text">Forming in mid-2019, just prior to a world of lockdowns and self-isolation, Casual Fan’s first EP, “I’ve Dreamt About These Days” was released in late 2020 to many nice comments by friends and family, as well as radio play on triple j and Sydney’s FBi Radio. In between live music shut-downs, the band has honed an energetic-yet-intimate live show, an experience in a constant state of reinvention, with the band being invited to appear on festival stages such as 2022’s Lost Paradise Festival.</p>
            <p className="bio-text">Inspired by a family road trip through the deserts of the American midwest as a thirteen year old listening to a collection of U2’s greatest hits, Casual Fan’s latest EP, ‘Bono’s America’, tells of mountains and canyons - the heights and depths of growing, and what it means to reflect on the diverse array of memories which shape a life. Above all, it tells of a search for connection, a cosmic road trip on a highway through the desert, looking for some friends to grow old with.</p>
            <p className="bio-text">Casual Fan is Nathan, Blair, Cam, Esther, Rei and Stevie.</p>
        </div>
    )
};