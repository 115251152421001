import React from "react";
import BA from "../pics/BA.jpg";

export default function Home() {
    return (
        <div className="announce">
            <h2 className="announce-title">New EP "Bono's America" Out Now</h2>
            <div className="announce-body">
                <div className="announce-body-one">
                    <img className="announce-art" src={BA} alt="" />
                </div>
                <div className="announce-body-two">
                    <p className="announce-notes">
                        Bono’s America was written in the middle of a 107-day lockdown in Sydney/Eora, Australia and is a memoir-like retelling, informed by the American landscape as I experienced it on a family trip as a 13-year-old, soundtracked by a collection of U2’s greatest hits.
                    </p>
                    <p className="announce-notes">
                        Recorded in mid-2022 with Liam Judson (Rolling Blackouts C.F), Bono’s America will take you on a journey through mountains, valleys and the spaces in between, all the while searching for connection — a cosmic road trip on a highway through the American desert looking for some friends to grow old with.
                    </p>
                    <div className="announce-buttons">
                        <a href="https://casualfan.bandcamp.com/album/bonos-america" className="announce-button" target="_blank" rel="noopener noreferrer">BUY</a>
                        <a href="https://open.spotify.com/album/6shE1AZw5UsWw5iAKvN8zq" className="announce-button" target="_blank" rel="noopener noreferrer">STREAM</a>
                    </div>
                </div>
            </div>
        </div>
    )
};