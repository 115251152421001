import React from "react";

export default function Links() {
    return (
        <div id="social-icons">
            <a href="https://www.facebook.com/casualfanmusic" target="_blank" rel="noopener noreferrer" className="fa fa-facebook"> </a>
            <a href="https://www.instagram.com/casualfanmusic" target="_blank" rel="noopener noreferrer" className="fa fa-instagram"> </a>
            <a href="https://twitter.com/casualfanmusic" target="_blank" rel="noopener noreferrer" className="fa fa-twitter"> </a>
            <a href="https://open.spotify.com/artist/1ivH8IH6j1U7lCgkuYxoUa" target="_blank" rel="noopener noreferrer" className="fa fa-spotify"> </a>
        </div>
    )
};