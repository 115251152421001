import React from "react";

export default function Videos() {
    return (
        <div>
            <iframe className="youtube-video" width="700" height="394" src="https://www.youtube.com/embed/i_1XB5iW6dQ" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <iframe className="youtube-video" width="700" height="394" src="https://www.youtube.com/embed/ZF7L6ToVM0Y" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <iframe className="youtube-video" width="700" height="394" src="https://www.youtube.com/embed/SGZY-meScPY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            <iframe className="youtube-video" width="700" height="394" src="https://www.youtube.com/embed/VoQ6miC5aV0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
    )
};